// prevent elements from being interacted with
@mixin uninteractText {
  // i don't know why overflow has to be set to hidden.
  // overflow: hidden;
  user-select: none;
  pointer-events: none;
}

@mixin invisibleEnabled {
  transition: opacity;

  &.invisible {
    opacity: 0;
  }
}

@mixin backgroundPosition {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.editingBox {
  border: 2px rgba(155, 155, 155, 1) dashed;
}

.dialogueBox {
  z-index: 10;
  display: block;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  @include uninteractText();
  @include invisibleEnabled();
  @include backgroundPosition();
}

.nameBox {
  display: block;
  z-index: 100;

  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @include uninteractText();
  @include invisibleEnabled();
  @include backgroundPosition();

  overflow: visible;
}

.textBox {
  display: block;
  z-index: 100;

  @include uninteractText();
  @include invisibleEnabled();
  @include backgroundPosition();

  .singleWord {
    white-space: nowrap;
  }
  .letter {
    display: inline-block;
    opacity: 0;

    &.visible {
      opacity: 1;
      transition: opacity;

      &.animated {
        animation-name: letterVisibilityAnimation;
        animation-duration: 0.14s;
        animation-iteration-count: 1;
        animation-timing-function: cubic-bezier(0.53, 0.15, 0.85, 0.43);
      }

      &.noAnim {
        animation: none !important;
      }
    }
  }
}

@keyframes letterScaleAnimation {
  0% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes letterVisibilityAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.choiceBox {
  // background-image: url("/choice.png");
  background-size: 100% 100%;
  padding: 0% 7%;

  z-index: 200;

  text-align: center;

  color: rgba(0, 0, 0, 0.95);

  pointer-events: all;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;

  &:hover::after {
    position: absolute;
    width: 100%;
    height: 100%;
    // needed on safari
    top: 0;
    left: 0;
  }
}

.choiceContainer {
  margin: auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -100%);
  -webkit-transform: translate(-50%, -50%);
  overflow: visible;
  z-index: 200;

  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @include uninteractText();
}

.loadingScreen {
  z-index: 1000;
  display: block;
  color: rgba(255, 255, 255, 0.95);
  background-color: #000000;
  font-weight: 400;
  text-align: center;
  padding: 80% 0%;
  font-family: "EB Garamond", serif;
}

.modalWrapper {
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @include uninteractText();

  .modal {
    box-shadow: 5px 5px 5px;
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: black;
    z-index: 101;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    // padding: 50px 20px;
    margin: 0px 24px;
    p {
      font-family: "EB Garamond", serif;
      font-size: 18px;
      line-height: 1.15em;
      text-align: center;
      pointer-events: all;
      margin: 0.6em 0px;
    }
    hr {
      margin-top: 20px;
      margin-bottom: 20px;
      border: 0;
      border-top: 1px solid rgba(0, 0, 0, 0.4);
      height: 1px;
      width: 90%;
      overflow: visible;
    }
    img {
      max-width: 70%;
    }

    .asdf {
      max-width: 100% !important;
    }
    .biglink {
      font-size: 24px;
    }
  }
}

.endScreen {
  z-index: 101;
  position: absolute;
  top: 50%;
  left: 50%;
  max-height: 80%;
  width: calc(100% - 50px);
  transform: translate(-50%, -50%);
  padding: 24px;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}

.interactable {
  pointer-events: all !important;
}
