@import "./firebase.scss";
@import "./breakpoints.scss";
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

html {
  width: 100%;
  height: 100%;
}

#root {
  position: relative;
  // z-index: 1;
  width: 100%;
  height: 100%;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  flex-direction: column;

  &.noTouch {
    pointer-events: none;
  }

  .overlay {
    z-index: 2;
  }
}

#preact-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  flex-direction: column;
  z-index: 1;
  pointer-events: none;
}

#container {
  position: relative;
  overflow: hidden;
  user-select: none;
}

.storyViewer {
  flex: 1;
  width: 100%;
}

#storyViewer {
  height: 100%;
  max-width: calc(100vh / 1.5);
  display: flex;
  flex-direction: column;
  margin: auto;
  pointer-events: all;
}

.pixiApplication {
  display: block;
}

// canvas {
//   display: block;
// }

[hidden] {
  display: none !important;
}

.entireLink::after {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.hk-highlight {
  animation: test;
  animation-duration: 1000ms;
}

@keyframes test {
  0% {
    background-color: auto;
  }
  50% {
    background-color: yellow;
  }
  100% {
    background-color: auto;
  }
}

.cardView {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: center;

  padding: 0px 50px;
  flex: 1;
  min-height: auto;
  margin-top: 64px;

  @include media("<phone") {
    flex-direction: column !important;
    align-items: stretch !important;
    padding: 10px 0px;
  }
}

.ant-carousel .slick-dots li.slick-active button {
  background: rgba(0, 0, 0, 0.7) !important;
}
.ant-carousel .slick-dots li button {
  background: #000000 !important;
  opacity: 0.4;
  height: 8px !important;
}

.landingCarousel {
  // background-color: grey;
  .carouselCard {
    width: 90vw;
    max-width: 300px;
    height: 100%;
    max-height: 450px;
    background-color: white;
    display: flex;
    align-items: center;
    // font-weight: bold;
    flex-direction: column;
    margin: auto;

    .text {
      border-top: 1px rgba(0, 0, 0, 0.3) solid;
      text-align: center;
      padding: 10px;
    }
  }
}

.clickable {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
